exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-es-404-tsx": () => import("./../../../src/pages/es/404.tsx" /* webpackChunkName: "component---src-pages-es-404-tsx" */),
  "component---src-templates-article-article-page-tsx": () => import("./../../../src/templates/article/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-article-page-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-breed-collection-breed-collection-listing-page-tsx": () => import("./../../../src/templates/breed/collection/BreedCollectionListingPage.tsx" /* webpackChunkName: "component---src-templates-breed-collection-breed-collection-listing-page-tsx" */),
  "component---src-templates-breed-detail-breed-page-tsx": () => import("./../../../src/templates/breed/detail/BreedPage.tsx" /* webpackChunkName: "component---src-templates-breed-detail-breed-page-tsx" */),
  "component---src-templates-breed-listing-breed-listing-page-tsx": () => import("./../../../src/templates/breed/listing/BreedListingPage.tsx" /* webpackChunkName: "component---src-templates-breed-listing-breed-listing-page-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-custom-pages-do-not-sell-tsx": () => import("./../../../src/templates/custom-pages/do-not-sell.tsx" /* webpackChunkName: "component---src-templates-custom-pages-do-not-sell-tsx" */),
  "component---src-templates-custom-pages-feeding-guide-direct-tsx": () => import("./../../../src/templates/custom-pages/feeding-guide-direct.tsx" /* webpackChunkName: "component---src-templates-custom-pages-feeding-guide-direct-tsx" */),
  "component---src-templates-custom-pages-pet-food-finder-tsx": () => import("./../../../src/templates/custom-pages/pet-food-finder.tsx" /* webpackChunkName: "component---src-templates-custom-pages-pet-food-finder-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-ingredients-tsx": () => import("./../../../src/templates/ingredients.tsx" /* webpackChunkName: "component---src-templates-ingredients-tsx" */),
  "component---src-templates-listing-article-listing-tsx": () => import("./../../../src/templates/listing/article-listing.tsx" /* webpackChunkName: "component---src-templates-listing-article-listing-tsx" */),
  "component---src-templates-listing-offers-listing-tsx": () => import("./../../../src/templates/listing/offers-listing.tsx" /* webpackChunkName: "component---src-templates-listing-offers-listing-tsx" */),
  "component---src-templates-listing-product-listing-tsx": () => import("./../../../src/templates/listing/product-listing.tsx" /* webpackChunkName: "component---src-templates-listing-product-listing-tsx" */),
  "component---src-templates-offer-tsx": () => import("./../../../src/templates/offer.tsx" /* webpackChunkName: "component---src-templates-offer-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pdf-pdf-tsx": () => import("./../../../src/templates/pdf/pdf.tsx" /* webpackChunkName: "component---src-templates-pdf-pdf-tsx" */),
  "component---src-templates-products-product-bundle-tsx": () => import("./../../../src/templates/products/product-bundle.tsx" /* webpackChunkName: "component---src-templates-products-product-bundle-tsx" */),
  "component---src-templates-products-product-non-food-tsx": () => import("./../../../src/templates/products/product-non-food.tsx" /* webpackChunkName: "component---src-templates-products-product-non-food-tsx" */),
  "component---src-templates-products-product-tsx": () => import("./../../../src/templates/products/product.tsx" /* webpackChunkName: "component---src-templates-products-product-tsx" */),
  "component---src-templates-ps-landing-page-tsx": () => import("./../../../src/templates/ps-landing-page.tsx" /* webpackChunkName: "component---src-templates-ps-landing-page-tsx" */),
  "component---src-templates-purina-perks-purina-perks-success-purina-perks-success-page-tsx": () => import("./../../../src/templates/purina-perks/PurinaPerksSuccess/PurinaPerksSuccessPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-purina-perks-success-purina-perks-success-page-tsx" */),
  "component---src-templates-purina-perks-receipt-history-receipt-history-page-tsx": () => import("./../../../src/templates/purina-perks/ReceiptHistory/ReceiptHistoryPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-receipt-history-receipt-history-page-tsx" */),
  "component---src-templates-purina-perks-reward-catalog-reward-catalog-page-tsx": () => import("./../../../src/templates/purina-perks/RewardCatalog/RewardCatalogPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-reward-catalog-reward-catalog-page-tsx" */),
  "component---src-templates-purina-perks-reward-checkout-reward-checkout-page-tsx": () => import("./../../../src/templates/purina-perks/RewardCheckout/RewardCheckoutPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-reward-checkout-reward-checkout-page-tsx" */),
  "component---src-templates-purina-perks-reward-details-reward-details-page-tsx": () => import("./../../../src/templates/purina-perks/RewardDetails/RewardDetailsPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-reward-details-reward-details-page-tsx" */),
  "component---src-templates-purina-perks-upload-receipt-upload-receipt-page-tsx": () => import("./../../../src/templates/purina-perks/UploadReceipt/UploadReceiptPage.tsx" /* webpackChunkName: "component---src-templates-purina-perks-upload-receipt-upload-receipt-page-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */)
}

